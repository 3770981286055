import { ReactNode, createContext, useContext, useMemo } from 'react';

import { ParticipantAPI } from '@focal-insights/sdk/api/participant';

interface Props {
    server?: string;
    children: ReactNode;
}

const AdHawkAPIContext = createContext<ParticipantAPI>(new ParticipantAPI());

export function useFocalInsightsAPI(): ParticipantAPI {
    return useContext(AdHawkAPIContext);
}

export default function FocalInsightsAPIProvider({ server, children }: Props) {
    const api = useMemo(() => new ParticipantAPI(server), [server]);

    return (
        <AdHawkAPIContext.Provider value={api}>
            {children}
        </AdHawkAPIContext.Provider>
    );
}
