import React, { useEffect } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { useRedirectInfo } from '../hooks/redirectInfo.js';
import { PageDef, PageWithHeader } from './GenericPage.js';

interface Props {
    error?: unknown;
    eventId?: string | null;
}

function errorToString(error: unknown): string {
    if (error instanceof Error) {
        return error.toString();
    }

    if (typeof error === 'string') {
        return error;
    }

    return 'Unknown error';
}

export function PageFatalError({ error, eventId }: Props) {
    const page: PageDef = { header: 'Error', p: [] };
    const { t } = useTranslation();

    page.p.push(
        <Trans i18nKey="page.error.textWithoutRedirect" t={t}>
            Unfortunately we encountered an error and cannot continue with the
            experiment. You may now close your browser.
        </Trans>,
    );

    if (eventId) {
        page.p.push(
            <pre style={{ whiteSpace: 'pre-wrap' }}>Event: {eventId}</pre>,
        );
    }

    if (error) {
        page.p.push(
            <pre style={{ whiteSpace: 'pre-wrap' }}>
                {errorToString(error)}
            </pre>,
        );
    }

    return <PageWithHeader page={page} />;
}

export function PageError({ error, eventId }: Props) {
    const page: PageDef = { header: 'Error', p: [] };
    const { t } = useTranslation();
    const { redirectTo, silentRedirect } = useRedirectInfo(true);

    useEffect(() => {
        if (silentRedirect && redirectTo) {
            window.location.href = redirectTo;
        }
    }, [silentRedirect, redirectTo]);

    if (redirectTo) {
        page.p.push(
            <Trans i18nKey="page.error.textWithRedirect" t={t}>
                Unfortunately we encountered an error and cannot continue with
                this part of the experiment.
            </Trans>,
        );
        page.button = t('button.continue', 'Continue');
    } else {
        page.p.push(
            <Trans i18nKey="page.error.textWithoutRedirect" t={t}>
                Unfortunately we encountered an error and cannot continue with
                the experiment. You may now close your browser.
            </Trans>,
        );
    }

    if (eventId) {
        page.p.push(
            <pre style={{ whiteSpace: 'pre-wrap' }}>Event: {eventId}</pre>,
        );
    }

    page.p.push(
        <pre style={{ whiteSpace: 'pre-wrap' }}>{errorToString(error)}</pre>,
    );

    if (redirectTo) {
        page.p.push(
            t(
                'page.error.clickToContinue',
                'Click the button to continue with the next part.',
            ),
        );
    }

    return (
        <PageWithHeader
            page={page}
            onClick={async () => {
                if (redirectTo) {
                    window.location.href = redirectTo;
                }
            }}
        />
    );
}
