import React, { ReactNode } from 'react';

import { css, cx } from '@emotion/css';
import { Button, Container, Grid } from '@mui/material';
import { Trans } from 'react-i18next';

type ParagraphDef = ReactNode;

export interface PageDef {
    image?: string;
    header: string;
    p: Array<ParagraphDef>;
    button?: 'last' | 'next' | ReactNode;
}

export interface PageProps {
    page: PageDef;
    onClick?: (isLast?: boolean) => void;
}

function Paragraph({ p }: { p: ParagraphDef }) {
    if (p === null || p === undefined) return <></>;

    if (
        typeof p === 'string' ||
        typeof p === 'number' ||
        typeof p === 'boolean'
    ) {
        return <p>{p}</p>;
    }

    return <>{p}</>;
}

export function Page({ page, onClick }: PageProps) {
    return (
        <>
            {page.image && (
                <Grid item xs={12} sm={4}>
                    <img
                        alt={page.header}
                        src={page.image}
                        width="100%"
                        style={{ maxWidth: 350 }}
                    />
                </Grid>
            )}

            <Grid item xs={12} sm={8}>
                <h2>{page.header}</h2>

                {page.p.map((para, index) => (
                    <Paragraph key={index.toString()} p={para} />
                ))}

                {page.button === 'next' && (
                    <>
                        <h3>
                            <Trans i18nKey="@@welcomeDone">Done?</Trans>
                        </h3>
                        <p>
                            <Trans i18nKey="@@welcomeIfSoPleaseClick">
                                If so, please click:
                            </Trans>
                        </p>

                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => onClick && onClick(false)}
                        >
                            <Trans i18nKey="@@welcomeNextButton">Next</Trans>
                        </Button>
                    </>
                )}

                {page.button === 'start' && (
                    <>
                        <h2>
                            <Trans i18nKey="@@welcomeReadyHeader">
                                Everything set?
                            </Trans>
                        </h2>
                        <p>
                            <Trans i18nKey="@@welcomeReadyText">
                                When you are comfortable and ready to begin, you
                                can start.
                            </Trans>
                        </p>

                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => onClick && onClick(true)}
                        >
                            <Trans i18nKey="@@welcomeStartButton">Start</Trans>
                        </Button>
                    </>
                )}

                {page.button !== 'next' &&
                    page.button !== 'start' &&
                    page.button !== undefined && (
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => onClick && onClick(true)}
                        >
                            {page.button}
                        </Button>
                    )}
            </Grid>
        </>
    );
}

const classes = {
    page: css({
        marginTop: 50,
    }),
    header: css({
        verticalAlign: 'bottom',
    }),
    headerItem: css({
        display: 'inline-block',
        margin: 0,
        verticalAlign: 'bottom',
        fontSize: 23,
        fontFamily: 'Helvetica',
    }),
};

export function PageWithHeader({ page, onClick }: PageProps) {
    return (
        <Container maxWidth="md" className={classes.page}>
            <Grid container spacing={3}>
                <Grid item xs={12} className={classes.header}>
                    <h1 className={classes.headerItem}>
                        <Trans i18nKey="@@applicationTitle">
                            Focal Insights
                        </Trans>
                    </h1>
                </Grid>

                <Page page={page} onClick={onClick} />
            </Grid>
        </Container>
    );
}
