import { DebugEvent } from '@focal-insights/sdk/events';

export function debugLogger(event: DebugEvent) {
    switch (event.level) {
        case 'INFO':
            console.info(event.message);
            break;
        case 'ERROR':
            console.error(event.message);
            break;
        case 'WARN':
            console.warn(event.message);
            break;
        case 'DEBUG':
            console.debug(event.message);
            break;
        default:
            console.log(event.message);
    }
}
