import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@mui/material/styles';

// Create a theme instance.
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#17cc9d',
            contrastText: 'white',
        },
    },
});

export default theme;
