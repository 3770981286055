export class FocalInsightsError extends Error {
    public constructor(
        private errorCode: string,
        message: string,
        options?: ErrorOptions | undefined,
    ) {
        super(`${errorCode}: ${message}`, options);
        Object.setPrototypeOf(this, new.target.prototype);
    }

    public get code(): string {
        return this.errorCode;
    }
}
