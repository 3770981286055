/**
 * Profile (define parameters and constants)
 * URL + Cookie (parameters and constants) -> Persist in cookie
 *
 * Resolve values from profile and protocol using parameters
 */
import { resolveVariablesString } from '@focal-insights/sdk/protocol';

import { useRecruitmentProfile } from '../context/RecruitmentProfileContext.js';
import { useSession } from '../context/SessionContext.js';

export type VariableNames = 'redirectOnComplete' | 'redirectOnError';

export function useAdHawkVariable(name: VariableNames) {
    const { session } = useSession();
    const profile = useRecruitmentProfile();

    try {
        switch (name) {
            case 'redirectOnComplete':
                return resolveVariablesString(
                    profile,
                    session.parameters,
                    profile.redirectOnComplete,
                );
            case 'redirectOnError':
                return resolveVariablesString(
                    profile,
                    session.parameters,
                    profile.redirectOnError,
                );
        }
    } catch (error) {
        if (error instanceof Error) {
            console.warn(`Could not resolve '${name}': ${error.message}.`);
        } else {
            console.warn(
                `Could not resolve '${name}': ${JSON.stringify(error)}.`,
            );
        }
        return;
    }
}
