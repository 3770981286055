import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useRef,
    useState,
} from 'react';

interface TrackerState {
    trackingSince: number;
    frames: number;
    withFaces: number;
}

export interface TrackerFunctions {
    createTracker(): string;
    deleteTracker(handle: string): boolean;
    handleFrame(hasFace: boolean): void;
    trackerState(handle: string): TrackerState;
}

export const TrackingContext = createContext<TrackerFunctions>(
    {} as TrackerFunctions,
);

export function TrackingProvider({ children }: { children: ReactNode }) {
    const trackers = useRef<{ [key: string]: TrackerState }>({});
    const counterRef = useRef(0);

    const value: TrackerFunctions = {
        createTracker: () => {
            const newId = counterRef.current.toString(10);
            counterRef.current += 1;
            trackers.current[newId] = {
                trackingSince: Date.now(),
                frames: 0,
                withFaces: 0,
            };

            return newId;
        },

        deleteTracker: (handle: string): boolean => {
            if (!(handle in trackers.current)) return false;
            delete trackers.current[handle];
            return true;
        },

        handleFrame: (hasFace: boolean): void => {
            for (const key of Object.keys(trackers.current)) {
                try {
                    trackers.current[key].frames += 1;
                    trackers.current[key].withFaces += hasFace ? 1 : 0;
                } catch (error) {}
            }
        },

        trackerState: (handle: string): TrackerState => {
            return trackers.current[handle];
        },
    };

    return (
        <TrackingContext.Provider value={value}>
            {children}
        </TrackingContext.Provider>
    );
}

export function useTrackingContext(): TrackerFunctions {
    return useContext(TrackingContext);
}
