import { Suspense } from 'react';

import { Outlet } from 'react-router-dom';

import { PageLoading } from '@/pages/PageLoading.js';

export default function Root() {
    return (
        <Suspense fallback={<PageLoading />}>
            <Outlet />
        </Suspense>
    );
}
