import { ReactNode, createContext, useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { RecruitmentProfile } from '@focal-insights/rest-api-definitions/participant';
import { resolveInheritance } from '@focal-insights/sdk/protocol';
import { decompressUuid } from '@focal-insights/utils/strings';

import { useFocalInsightsAPI } from './FocalInsightsAPIContext.js';

interface Props {
    children: ReactNode;
}

type RecruitmentProfileWithId = RecruitmentProfile & { id: string };

const AdHawkRecruitmentProfile = createContext<RecruitmentProfileWithId | null>(
    null,
);

function normalizeId(id: string): string {
    if (id.length === 36) return id;
    return decompressUuid(id);
}

export function useRecruitmentProfile(): RecruitmentProfileWithId {
    const profile = useContext(AdHawkRecruitmentProfile);
    if (!profile) throw new Error('Internal error.');

    return profile;
}

export function RecruitmentProfileProvider({ children }: Props) {
    const api = useFocalInsightsAPI();
    const params = useParams();
    const { i18n } = useTranslation();

    if (!params.recruitmentId) throw new Error('RecruitmentId not set');

    const recruitmentId = normalizeId(params.recruitmentId);

    const { data } = useQuery(['recruitment', recruitmentId], () =>
        api.getRecruitment(recruitmentId),
    );
    if (!data) throw new Error('Internal error.');

    const resolvedProfile = resolveInheritance(
        data.profiles,
        data.profiles[data.profileId],
    );

    // FIXME: Override for testing <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

    const profile = {
        id: recruitmentId,
        ...resolvedProfile,

        //consentRequired: true,
        //fullscreenRequired: false,

        constants: {
            'internal.error_code': '',
            ...resolvedProfile.constants,
        },
    };

    useEffect(() => {
        if (profile.language) {
            i18n.changeLanguage(profile.language).catch(() => {});
        }
    }, []);

    return (
        <AdHawkRecruitmentProfile.Provider value={profile}>
            {children}
        </AdHawkRecruitmentProfile.Provider>
    );
}
