import React, { Suspense } from 'react';

import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';

import Root from '@/routes/root.js';

import { PageFatalError } from './pages/PageError.js';

const ParticipateDialog = React.lazy(() => import('./routes/index.js'));
const RecruitmentIdPage = React.lazy(
    () => import('./routes/$recruitmentId.js'),
);
const ScreenPage = React.lazy(
    () => import('./routes/$recruitmentId/screen.js'),
);
const WelcomePage = React.lazy(
    () => import('./routes/$recruitmentId/Welcome.js'),
);

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        errorElement: <PageFatalError />,
        children: [
            {
                path: ':recruitmentId',
                element: (
                    <Suspense>
                        <RecruitmentIdPage />
                    </Suspense>
                ),
                children: [
                    {
                        path: 'welcome/:page',
                        element: (
                            <Suspense>
                                <WelcomePage
                                    onStart={(navigate) =>
                                        navigate('../screen/0')
                                    }
                                />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'continue',
                    },
                    {
                        path: 'screen',
                        element: (
                            <Suspense>
                                <Outlet />
                            </Suspense>
                        ),
                        children: [
                            {
                                path: ':path',
                                element: <ScreenPage />,
                            },
                            {
                                path: '*',
                                element: <Navigate to="0" replace />,
                            },
                        ],
                    },
                    {
                        path: '',
                        element: <Navigate to="welcome/1" replace />,
                    },
                ],
            },
            {
                path: '',
                element: <ParticipateDialog />,
            },
        ],
    },
]);

export default router;
