import qs from 'query-string';

import {
    ParameterOptions,
    RecruitmentProfile,
} from '@focal-insights/rest-api-definitions/participant';

type Parameters = { [key: string]: ParameterOptions };

/**
 * Returns a dictionary with default parameters given a dictionary with parameter options.
 */
export function getDefaultValues(parameters: Parameters) {
    const defaultValues: { [key: string]: string } = {};
    for (const key in parameters) {
        if (parameters.hasOwnProperty(key)) {
            const defaultValue = parameters[key].defaultValue;
            if (defaultValue) {
                defaultValues[key] = defaultValue;
            }
        }
    }

    return defaultValues;
}

/**
 * Returns a dictionary with default parameters as specified in the recruitment profile.
 */
export function getDefaultValuesFromProfile(profile: RecruitmentProfile): {
    [key: string]: string;
} {
    if (!profile.parameters) return {};

    return getDefaultValues(profile.parameters);
}

/**
 * Returns a dictionary containing all query-string parameters.
 *
 * @param search Query string.
 * @returns Name-value dictionary.
 */
export function getParametersFromSearch(search: string): {
    [key: string]: string;
} {
    const params = qs.parse(search);
    const result: { [key: string]: string } = {};

    for (const key of Object.keys(params)) {
        const param = params[key];

        if (typeof param === 'string') {
            result[key] = param;
        }
    }

    return result;
}

/**
 * Returns a dictionary containing parameters from a JSON-encoded cookie.
 */
export function getParametersFromCookie(value?: string): {
    [key: string]: string;
} {
    if (!value) return {};
    return JSON.parse(value) ?? {};
}
