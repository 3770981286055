import { SessionState } from '@focal-insights/sdk';

import { useRecruitmentProfile } from '../context/RecruitmentProfileContext.js';
import { useSession } from '../context/SessionContext.js';
import { useAdHawkVariable } from './parameters.js';

export function useRedirectInfo(error: boolean) {
    const profile = useRecruitmentProfile();
    const redirectOnComplete = useAdHawkVariable('redirectOnComplete');
    const redirectOnError = useAdHawkVariable('redirectOnError');
    const { state } = useSession();

    const silentRedirect =
        profile.silentRedirect &&
        (state === SessionState.Closed || state === SessionState.Suspended);

    if (error) {
        return {
            redirectTo: redirectOnError ?? redirectOnComplete,
            silentRedirect,
        };
    }

    return {
        redirectTo: redirectOnComplete,
        silentRedirect,
    };
}
