import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Open Sans', Arial, Helvetica, Sans-Serif;
    font-size: 12pt;
    padding: 0;
    margin: 0;
  }
`;

export default GlobalStyle;
