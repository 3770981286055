import { ReactNode, StrictMode, useEffect, useMemo } from 'react';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { SnackbarProvider } from 'notistack';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
import {
    RouterProvider,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

import { getAPIAddress } from '@focal-insights/sdk/config';

import FocalInsightsAPIProvider from './context/FocalInsightsAPIContext.js';
import { TrackingProvider } from './context/TrackingContext.js';
import i18n from './i18n.js';
import { PageFatalError } from './pages/PageError.js';
import { queryClient } from './queryClient.js';
import router from './routes.js';
import GlobalStyle from './styles/globalStyles.js';
import theme from './styles/theme.js';

Sentry.init({
    dsn: 'https://5dae8c8878dd4dfab48350f6598436f8@sentry.ocstro.net/2',
    release: 'participant@' + import.meta.env.REACT_APP_VERSION,
    environment: import.meta.env.NODE_ENV,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
    tracePropagationTargets: ['focal-insights.com', 'ocstro.net'],
    tracesSampleRate: 1.0,
});

const container = document.getElementById('root');

function Styling({ children }: { children: ReactNode }) {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

function AppProviders({ children }: { children: ReactNode }) {
    const server = useMemo(() => getAPIAddress(), []);
    return (
        <FocalInsightsAPIProvider server={server}>
            <TrackingProvider>{children}</TrackingProvider>
        </FocalInsightsAPIProvider>
    );
}

const root = createRoot(container!);
root.render(
    <StrictMode>
        <Styling>
            <Sentry.ErrorBoundary
                fallback={({ error, eventId }) => (
                    <PageFatalError error={error} eventId={eventId} />
                )}
            >
                <HelmetProvider>
                    <I18nextProvider i18n={i18n}>
                        <QueryClientProvider client={queryClient}>
                            <SnackbarProvider maxSnack={3}>
                                <AppProviders>
                                    <RouterProvider router={router} />
                                </AppProviders>
                            </SnackbarProvider>
                        </QueryClientProvider>
                    </I18nextProvider>
                </HelmetProvider>
            </Sentry.ErrorBoundary>
        </Styling>
    </StrictMode>,
);
