import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
    what?: string;
}

export function PageLoading({ what }: Props) {
    const { t } = useTranslation();

    return (
        <div
            style={{
                fontFamily: 'helvetica, sans-serif',
                fontSize: '2rem',
                display: 'flex',
                height: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '2rem',
            }}
        >
            <CircularProgress size={'2rem'} color="info" />
            <span>{t(what ?? 'loading.default')}</span>
        </div>
    );
}
