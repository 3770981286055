import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';

import { useLocation } from 'react-router-dom';

import { Session, SessionState, connect } from '@focal-insights/sdk';
import { getAPIAddress } from '@focal-insights/sdk/config';
import {
    ErrorEvent,
    SessionStateChangeEvent,
    StreamStartEvent,
} from '@focal-insights/sdk/events';
import { resolveVariablesString } from '@focal-insights/sdk/protocol';
import { SessionCloseReason, StreamState } from '@focal-insights/sdk/session';
import { has } from '@focal-insights/utils/types';

import { PageLoading } from '../pages/PageLoading.js';
import { FocalInsightsError } from '../utils/FocalInsightsError.js';
import { debugLogger } from '../utils/debugLogger.js';
import { getParametersFromSearch } from '../utils/parameters.js';
import { useRecruitmentProfile } from './RecruitmentProfileContext.js';

interface ISessionContext {
    session: Session;
    state: SessionState;
    cameraState: StreamState;
}

export const SessionContext = createContext<ISessionContext>(
    {} as ISessionContext,
);

interface Props {
    children: ReactNode;
}

function useForeignId(): string | undefined {
    const recruitmentProfile = useRecruitmentProfile();
    const { search } = useLocation();

    try {
        return resolveVariablesString(
            recruitmentProfile,
            getParametersFromSearch(search),
            recruitmentProfile.foreignId,
        );
    } catch {
        // Ignore error
    }
}

/**
 * This is the Focal Insights provider, exposing functions to all child components.
 */
export function SessionProvider({ children }: Props) {
    const [state, setState] = useState<SessionState>(SessionState.Disconnected);
    const [error, setError] = useState<unknown>(undefined);
    const [cameraState, setCameraState] = useState<StreamState>(
        StreamState.Closed,
    );
    const [session, setSession] = useState<Session>();
    const sessionPromise = useRef<Promise<void> | null>(null);
    const { search } = useLocation();

    const recruitmentProfile = useRecruitmentProfile();
    const foreignId = useForeignId();

    const handleSessionException = (reason: unknown) => {
        setState(SessionState.Error);
        setError(reason);
    };

    useEffect(() => {
        if (!sessionPromise.current) {
            sessionPromise.current = connect(
                recruitmentProfile.id,
                foreignId,
                getAPIAddress(),
            )
                .then(async (session) => {
                    // Update session parameters from query string
                    const parameters = getParametersFromSearch(search);
                    await session.updateParameters(
                        Object.keys(parameters).map((name) => ({
                            name,
                            value: parameters[name],
                        })),
                    );
                    return session;
                })
                .then(setSession)
                .catch(handleSessionException);
        }
    }, []);

    const handleSessionStateChange = useCallback(
        (event: SessionStateChangeEvent) => {
            setState(event.target.state);
            setCameraState(event.target.cameraState);
        },
        [],
    );

    const handleStreamStart = useCallback((event: StreamStartEvent) => {
        setState(event.target.state);
        setCameraState(event.target.cameraState);
    }, []);

    const handleSessionError = useCallback((event: ErrorEvent) => {
        console.error(event.error);
        setError(event);
    }, []);

    useEffect(() => {
        if (!session) return;

        session.addEventListener(
            'sessionstatechange',
            handleSessionStateChange,
        );
        session.addEventListener('streamstart', handleStreamStart);
        session.addEventListener('error', handleSessionError);
        session.addEventListener('debug', debugLogger);

        if (session.state !== SessionState.Connected) {
            session.connect().catch(handleSessionException);
        }

        return () => {
            session.removeEventListener(
                'sessionstatechange',
                handleSessionStateChange,
            );
            session.removeEventListener('streamstart', handleStreamStart);
            session.removeEventListener('error', handleSessionError);
            session.removeEventListener('debug', debugLogger);
        };
    }, [session]);

    if (error) {
        const errorCode =
            session?.closeReason ?? SessionCloseReason.UnknownError;

        if (error instanceof Error) {
            throw new FocalInsightsError(errorCode, error.message, {
                cause: error,
            });
        }
        if (error instanceof ErrorEvent) {
            throw new FocalInsightsError(errorCode, error.error, {
                cause: error.cause,
            });
        } else if (typeof error === 'string') {
            throw new FocalInsightsError(errorCode, error);
        } else if (has(error, 'statusText')) {
            const message = `Could not setup session: ${error.statusText}`;
            throw new FocalInsightsError(errorCode, message);
        }

        throw new FocalInsightsError(errorCode, 'Could not setup session.');
    }

    if (!session) {
        return <PageLoading what="loading.session" />;
    }

    return (
        <SessionContext.Provider value={{ session, state, cameraState }}>
            {children}
        </SessionContext.Provider>
    );
}

export function useSession(): ISessionContext {
    return useContext(SessionContext);
}
