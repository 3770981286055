import { defaultImport } from 'default-import';
import i18n, { Resource } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

defaultImport(i18n)
    .use(initReactI18next)
    .use(defaultImport(Backend))
    .use(defaultImport(LanguageDetector))
    .init({
        partialBundledLanguages: true,
        fallbackLng: 'en',
        debug: import.meta.env.DEV,

        detection: {
            order: ['querystring', 'sessionStorage', 'navigator'],
            lookupQuerystring: 'lng',
            lookupSessionStorage: 'lng',
            caches: ['sessionStorage'],
        },

        interpolation: {
            escapeValue: false,
        },

        ns: ['translation'],
        defaultNS: 'translation',

        react: {
            useSuspense: true,
        },
    })
    .catch(() => {});

export default i18n;
